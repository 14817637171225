/* Main Loader Specific CSS */

.loader-wrapper::after {
    content: ' ';
    position: fixed;
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    height: 100vh;
  }
  .loader-wrapper{
    height: 100vh;
    display: flex !important;
    align-items: center;
  }
  .loader-img {
    height: 150px;
    width: 150px;
    left: 50%;
    top: 50%;
    z-index: 99999;
    position: fixed;
    margin: -70px 0 0 -70px;
  }
  
  /* Component Loader Specific CSS */
  
  .component-loader-wrapper::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.001);
  }
  
  .component-loader-img {
    height: 150px;
    width: 150px;
    left: 50%;
    top: 50%;
    z-index: 9;
    position: absolute;
    margin: -70px 0 0 -70px;
  }
  ._loading_overlay_overlay {
    position: fixed !important;
  }